// 清空页面栈并跳转的函数
export function clearHistoryAndRedirect(url: string) {
    window.location.href = url; // 直接跳转

    if (window.history && window.history.pushState) {
        // 如果浏览器支持history API
        window.history.pushState('', '', url); // 更新当前历史记录条目的URL
        window.history.replaceState(null, '', window.location.pathname); // 清空页面栈
    } else {
        window.location.href = url; // 不支持history API，直接跳转
    }
}

export function jsonSort(obj: Object) {
    return JSON.parse(JSON.stringify(obj, Object.keys(obj).sort()));
}

export function generateRandomString(length: number) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}