import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import reportWebVitals from './reportWebVitals';
import {
    createBrowserRouter,
    Navigate,
    RouterProvider,
} from "react-router-dom";
import { HomePage } from './routes/index/home/home';
import { OrderPage } from './routes/index/order/order';
import { MinePage } from './routes/index/mine/mine';
import { LoginPage } from './routes/login/login';
import { RegisterPage } from './routes/login/register';
import { ISigningPage } from './routes/login/signing';
import { OrderDetailPage } from './routes/index/home/actions/detail';
import { RegisterBindPage } from './routes/login/bind';
import { UserInfoPage } from './routes/index/mine/info';
import { ChangeMobilePage } from './routes/index/mine/changeMobile';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

const router = createBrowserRouter([
    {
        path: "/",
        element: <Navigate to={'/home'}></Navigate>,
    },
    {
        path: "/login",
        element: <LoginPage />,
    },
    {
        path: "/register",
        element: <RegisterPage />
    },
    {
        path: "/bind",
        element: <RegisterBindPage />
    },
    {
        path: "/sign",
        element: <ISigningPage />
    },
    {
        path: "/home",
        element: <HomePage />,
    },
    {
        path: "/order",
        element: <OrderPage />,
    },
    {
        path: "/mine",
        element: <MinePage />,
    },
    {
        path: "/changeMobile",
        element: <ChangeMobilePage />
    },
    {
        path: "/info",
        element: <UserInfoPage />,
    }
]);

root.render(
    <RouterProvider router={router} />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
