import { Button, Card, Dialog, Divider, DotLoading, ErrorBlock, InfiniteScroll, NavBar, Popup, PullToRefresh, Result, Skeleton, TabBar, Tabs, Toast } from "antd-mobile";
import { tabs } from "../index";
import { useNavigate } from "react-router-dom";

import "./order.scss";
import { AppstoreOutline, TextOutline } from "antd-mobile-icons";
import { useEffect, useState } from "react";
import { IOrderListData, IOrderListSearchForm, cancelOrder, getOrderList } from "../../../apis/order";
import { OrderDetailPage } from "../home/actions/detail";
import { SettleForm } from "./settle/settle";
import { IUserInfoRes } from "../../../apis/user";

import flagImg from '@assets/icon/flag.png';
import buildingImg from '@assets/icon/building.png';
import dateImg from '@assets/icon/date.png';

export function OrderPage() {
    const [isLogin, setIsLogin] = useState(false);
    useEffect(() => {
        let userStr = sessionStorage.getItem("user");
        if (userStr) {
            let user: IUserInfoRes = JSON.parse(userStr);

            if (user.is_user_sign) {
                setIsLogin(true);
            }
        }
    }, []);

    const navigate = useNavigate();
    const onTabBarChange = (key: string) => {
        switch (key) {
            case 'home':
                navigate('/home');
                break;
            case 'mine':
                navigate('/mine');
                break;

            default:
                break;
        }
    };

    // 加载列表数据
    const [isTaskListLoading, setIsTaskListLoading] = useState(false);
    const [form, setForm] = useState<IOrderListSearchForm>({
        page: 1,
        size: 20,
        nav: 1
    });
    const [taskList, setTaskList] = useState<IOrderListData[]>([
        // {
        //     id: 0,
        //     company_id: 0,
        //     service_provider_id: 0,
        //     task_id: 0,
        //     status: 0,
        //     show_status_text: '111',
        //     taking_at: 'string',
        //     finish_at: 'string',
        //     company: {
        //         id: 0,
        //         name: 'string'
        //     },
        //     service_provider: {
        //         id: 0,
        //         name: 'string',
        //         region_text: null
        //     },
        //     task: {
        //         id: 0,
        //         title: 'string',
        //         money: 1000
        //     }
        // }
    ]);
    const loadData = async (isRefresh: boolean = false, newForm?: IOrderListSearchForm) => {
        setIsTaskListLoading(true);

        let reqForm = newForm ?? form;

        if (isRefresh) {
            reqForm['page'] = 1;
        }

        try {
            let res = await getOrderList(reqForm);
            if (isRefresh) {
                setTaskList([...res.data.list]);
            } else {
                setTaskList(data => [...data, ...res.data.list]);
            }
            if (res.data.list.length < reqForm.size) {
                setHasMore(false);
            }
            setForm({
                ...reqForm,
                page: reqForm.page + 1,
            })
        } catch (e) {
            console.error(e);
        }

        setIsTaskListLoading(false);
    };

    // 下拉刷新
    const onRefresh = async () => {
        loadData(true);
    };

    // 无限滚动
    const [hasMore, setHasMore] = useState(true);
    const InfiniteScrollContent = ({ hasMore }: { hasMore?: boolean }) => {
        return (
            <>
                {hasMore ? (
                    <>
                        <span>加载中</span>
                        <DotLoading />
                    </>
                ) : taskList.length > 0 ? (
                    <span>--- 没有更多数据 ---</span>
                ) : null}
            </>
        )
    }


    const [detailId, setDetailId] = useState<number>();
    // 查看详情
    const [visible1, setVisible1] = useState(false)
    // 完成表单
    const [visible2, setVisible2] = useState(false)
    const [required, setRequired] = useState(false);

    const toCancel = (id: number) => {
        Dialog.confirm({
            title: "提示",
            content: "要放弃此订单吗？",
            onConfirm: async () => {
                let res = await cancelOrder({ order_id: id });
                Toast.show({
                    icon: 'success',
                    content: res.msg,
                    afterClose() {
                        loadData(true);
                    }
                })
            }
        });
    }

    return (
        <div className="order">
            <Tabs
                onChange={(key) => {
                    setForm(data => {
                        let form = {
                            ...data,
                            nav: Number(key)
                        };
                        if (isLogin) {
                            loadData(true, form);
                        }
                        return form;
                    }

                    );
                }}
                defaultActiveKey={'1'}
            >
                <Tabs.Tab title='已领取' key='1' />
                <Tabs.Tab title='已完成' key='2' />
                <Tabs.Tab title='已结算' key='3' />
            </Tabs>

            <div className="content">
                {
                    isLogin ?
                        <div className="scroll-y">
                            <PullToRefresh
                                onRefresh={onRefresh}
                            >
                                {
                                    !isTaskListLoading ?
                                        taskList.length > 0 ?
                                            taskList.map(order => (
                                                <div className="item" key={order.id}>
                                                    <Card
                                                        style={{ borderRadius: '10px' }}
                                                    >
                                                        <div onClick={() => {
                                                            setDetailId(order.task_id);
                                                            setVisible1(true);
                                                        }}>
                                                            <div className="title"><img src={flagImg} className="icon" /><span>{order.task.title}</span></div>
                                                            <div className="company"><img src={buildingImg} className="icon" /><span>{order.company.name} - {order.service_provider.name}</span></div>
                                                            <div className="company"><img src={dateImg} className="icon" /><span>接单日期：{order.taking_at}</span></div>
                                                            <div className="sub">
                                                                <div> </div>
                                                                <div style={{ color: 'red', fontSize: 20 }}>¥{order.task.money}</div>
                                                            </div>
                                                        </div>
                                                        <Divider />
                                                        {
                                                            form.nav === 1 &&
                                                            <div className="bottoms">
                                                                <Button
                                                                    fill='outline'
                                                                    style={{ borderRadius: 20, padding: '5px 15px' }}
                                                                    onClick={() => toCancel(order.id)}>
                                                                    放弃订单
                                                                </Button>

                                                                <Button
                                                                    color='primary'
                                                                    fill='solid'
                                                                    style={{ borderRadius: 20, padding: '5px 15px' }}
                                                                    onClick={() => {
                                                                        setDetailId(order.id);
                                                                        setRequired(order.is_upload_achievement ?? false)
                                                                        setVisible2(true);
                                                                    }}>
                                                                    完成
                                                                </Button>
                                                            </div>
                                                        }
                                                        {
                                                            form.nav === 2 &&
                                                            <div className="bottoms-text">
                                                                <div>完成时间：{order.finish_at}</div>
                                                                <div>{order.show_status_text}</div>
                                                            </div>
                                                        }
                                                        {
                                                            form.nav === 3 &&
                                                            <div className="bottoms-text">
                                                                <div>结算时间：{order.settle_at}</div>
                                                                <div>{order.show_status_text}</div>
                                                            </div>
                                                        }
                                                    </Card>
                                                </div>
                                            ))
                                            : <ErrorBlock status='empty' />
                                        : <Skeleton.Paragraph lineCount={5} animated style={{ padding: 10 }} />
                                }
                            </PullToRefresh>
                            <InfiniteScroll loadMore={() => loadData()} hasMore={hasMore}>
                                <InfiniteScrollContent hasMore={hasMore} />
                            </InfiniteScroll>
                        </div>
                        : <Result
                            status='info'
                            title='暂未签约'
                            description='注册信息并签约后可在此处查看并处理接单信息'
                        />
                }

                {
                    detailId && visible1 &&
                    <Popup
                        visible={true}
                        onMaskClick={() => {
                            setVisible1(false)
                        }}
                        onClose={() => {
                            setVisible1(false)
                        }}
                        showCloseButton
                    >
                        <div
                            style={{ height: '80vh', overflowY: 'scroll' }}
                        >
                            <OrderDetailPage id={detailId} />
                        </div>
                    </Popup>
                }

                {
                    detailId && visible2 &&
                    <Popup
                        visible={true}
                        onClose={() => {
                            setVisible2(false)
                        }}
                        showCloseButton
                    >
                        <div
                            style={{ height: '60vh', overflowY: 'scroll' }}
                        >
                            <SettleForm
                                id={detailId}
                                required={required}
                                onFinish={() => {
                                    setVisible2(false);
                                    loadData(true);
                                }}
                            />
                        </div>
                    </Popup>
                }
            </div>

            <TabBar safeArea defaultActiveKey={'order'} onChange={onTabBarChange}>
                {tabs.map(item => (
                    <TabBar.Item key={item.key} icon={item.icon} title={item.title} />
                ))}
            </TabBar>
        </div>
    );
}