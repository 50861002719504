import { Button, Form, ImageUploadItem, ImageUploader, Input, Toast } from "antd-mobile";
import { IFinishOrderForm, finishOrder } from "../../../../apis/order";
import { uploadFile } from "../../../../apis/common";
import Compressor from "compressorjs";

export function SettleForm({ id, onFinish, required }: { id: number, onFinish: Function, required: boolean }) {
    const [form] = Form.useForm<IFinishOrderForm>();

    const onSubmit = async () => {
        try {
            await form.validateFields();
        } catch (error) {
            return;
        }

        let _form = form.getFieldsValue(true);

        const values = {
            ..._form,
            order_id: id,
        };

        if (_form.finish_files) {
            values['finish_files'] = _form.finish_files.map((e: ImageUploadItem) => e.key);
        }

        if (required && (!values.finish_files || values.finish_files.length === 0)) {
            Toast.show({
                content: '请至少上传一个附件证明'
            });
            return;
        }

        console.log(values);


        Toast.show({
            icon: 'loading',
            content: '正在提交',
            duration: 0
        });

        try {
            let res = await finishOrder(values);
            Toast.clear();
            Toast.show({
                icon: 'success',
                content: res.msg,
                afterClose() {
                    onFinish()
                }
            })
        } catch (e) {
            console.error(e);
        }
    }

    const onUpload = async (rawFile: File) => {
        // 压缩
        return await new Promise<ImageUploadItem>((resolve, reject) => {
            new Compressor(rawFile, {
                quality: 0.6,
                success(result) {
                    let file = new File([result], rawFile.name);
                    uploadFile({ file: file, type: 'order' }).then(res => {
                        resolve({
                            url: res.data.full_link,
                            key: res.data.file_id
                        });
                    })
                },
                error(err) {
                    console.log(err.message);
                    Toast.show({
                        icon: 'fail',
                        content: '压缩过程出现错误'
                    });
                    reject();
                },
            });
        });
    };

    return <div>
        <Form
            form={form}
            footer={
                <Button block color='primary' onClick={onSubmit} size='large'>
                    提交
                </Button>
            }
        >
            <Form.Item
                name='finish_content'
                label='完成情况'
                rules={[{ required: true, message: '完成情况不能为空' }]}
            >
                <Input placeholder='请填写完成情况' />
            </Form.Item>

            <Form.Item
                name='finish_files'
                label='附件上传'
            >
                <ImageUploader
                    upload={onUpload}
                />
            </Form.Item>

        </Form>
    </div>
}