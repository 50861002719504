import { useState } from "react";
import { IMobileLoginForm, changeMobile, getUserInfo, mobileLogin } from "../../../apis/user";
import { Button, Form, Input, Toast, SafeArea, Dialog } from "antd-mobile";

import { codeType, sendVCode } from "../../../apis/common";
import { clearHistoryAndRedirect } from "../../../utils/utils";

export function ChangeMobilePage() {
    const [form] = Form.useForm<IMobileLoginForm>();
    const onSubmit = async () => {
        try {
            await form.validateFields();
        } catch (error) {
            return;
        }

        const result = await Dialog.confirm({
            title: '提示',
            content: '确定要修改手机号吗',
        });

        if (result) {
            const values = {
                ...form.getFieldsValue(true)
            };

            Toast.show({
                icon: 'loading',
                content: '正在提交',
                duration: 0
            });

            try {
                let res = await changeMobile(values);
                let userInfoRes = await getUserInfo();
                if (userInfoRes) {
                    let userInfo = userInfoRes.data;
                    sessionStorage.setItem('user', JSON.stringify(userInfo));
                }
                Toast.clear();
                Toast.show({
                    icon: 'success',
                    content: res.msg,
                    afterClose() {
                        clearHistoryAndRedirect("/mine")
                    }
                });
            } catch (e) {
                console.error(e);
            }
        }
    }

    const [counter, setCounter] = useState(0);
    const send = async () => {
        if (counter > 0) return;

        try {
            let { mobile } = await form.validateFields(['mobile']);

            setCounter(60);

            let counterId = setInterval(() => {
                setCounter(data => {
                    if (data > 0) {
                        return data - 1;
                    } else {
                        clearInterval(counterId);
                        return 0;
                    }
                });
            }, 1000);

            let res = await sendVCode({
                mobile,
                type: codeType.bind
            });

            Toast.show({
                content: res.msg,
            });
        } catch (e) {
            console.error(e);
        }
    }

    return <div>
        <Form
            form={form}
            footer={
                <Button block color='primary' onClick={onSubmit} size='large'>
                    提交
                </Button>
            }
        >
            <Form.Header>更换手机号</Form.Header>

            <Form.Item
                name='mobile'
                label='手机号码'
                rules={[
                    { required: true, message: '手机号码不能为空' },
                    {
                        pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
                        message: '请输入正确的手机号码',
                    },
                ]}
            >
                <Input placeholder='请输入手机号码' maxLength={11} />
            </Form.Item>

            <Form.Item
                name="code"
                label='短信验证码'
                rules={[{ required: true, message: '短信验证码不能为空' }]}
                extra={
                    <Button
                        size='small'
                        color="primary"
                        fill="none"
                        onClick={send}
                        disabled={counter > 0}
                    >
                        {counter > 0 ? `${counter}s` : '发送验证码'}
                    </Button>
                }
            >
                <Input placeholder='请输入' />
            </Form.Item>
        </Form>

        <SafeArea position='bottom' />
    </div>
}
