import { TabBar, Button, Avatar, List, NavBar, Dialog, Divider } from "antd-mobile";
import { useNavigate } from "react-router-dom";
import { tabs } from "..";
import { InformationCircleOutline, SendOutline, LinkOutline, PhonebookOutline } from "antd-mobile-icons";

import bgImg from '@assets/bg.png';
import func1Img from '@assets/icon/func1.png';
import func2Img from '@assets/icon/func2.png';
import func3Img from '@assets/icon/func3.png';
import func4Img from '@assets/icon/func4.png';
import arrowImg from '@assets/icon/right_arrow.png';

import './mine.scss';
import { useEffect, useState } from "react";
import { IUserInfoRes, getUserInfo } from "../../../apis/user";

export function MinePage() {
    const [userInfo, setUserInfo] = useState<IUserInfoRes>();

    // 获取用户信息
    const initUserInfo = async () => {
        // 预先加载用户信息
        let userStr = sessionStorage.getItem('user');
        if (userStr) {
            let user = JSON.parse(userStr);
            setUserInfo(user);
        }

        // 刷新用户信息
        let token = sessionStorage.getItem('token');
        if (token) {
            let userInfoRes = await getUserInfo();
            if (userInfoRes) {
                console.log(userInfoRes);

                let userInfo = userInfoRes.data;
                sessionStorage.setItem('user', JSON.stringify(userInfo));
                setUserInfo(userInfo);
            }
        }
    }

    useEffect(() => {
        initUserInfo();
    }, []);

    const navigate = useNavigate();
    const onTabBarChange = (key: string) => {
        switch (key) {
            case 'home':
                navigate('/home');
                break;

            case 'order':
                navigate('/order');
                break;

            default:
                break;
        }
    };

    // 跳转
    const nav = async (path: string) => {
        if (!userInfo) {
            navigate('/login');
            return;
        }

        if (!userInfo.is_user_auth) {
            Dialog.confirm({
                title: "提示",
                content: "您尚未注册信息，前往登记吗？",
                onConfirm: async () => {
                    window.location.href = '/register';
                }
            });
            return;
        }
        if (!userInfo.is_user_sign) {
            Dialog.confirm({
                title: "提示",
                content: "您尚未签约，前往签约吗？",
                onConfirm: async () => {
                    window.location.href = '/sign';
                }
            });
            return;
        }

        switch (path) {
            case 'info':
                window.location.href = '/info';
                break;
            case 'changeMobile':
                window.location.href = '/changeMobile';
                break;
            default:
                break;
        }
    }

    return (
        <div className="mine">
            <div className="content">
                <div
                    className="user"
                    style={{ backgroundImage: `url(${bgImg})`, backgroundSize: 'cover', backgroundPosition: 'center bottom' }}
                    onClick={() => nav('info')}
                >
                    <Avatar src={userInfo?.user_info?.avatar_link ?? userInfo?.oauth_user.headimgurl ?? ''} className="avatar" />
                    {
                        userInfo
                            ? <div className="info">
                                <div className="name">{userInfo.user_info?.nickname ?? userInfo.oauth_user.nickname}</div>
                                <div className="phone">{userInfo.user_info?.mobile ?? '未认证'}</div>
                            </div>
                            : <div className="info">
                                <div className="name">未登录</div>
                                <div className="phone">点击此处登录</div>
                            </div>
                    }
                </div>
                {
                    userInfo ?
                        <div className="wallet">
                            <div>
                                <div>钱包余额 </div>
                                <div className="money">¥ 0.00</div>
                            </div>
                            <Button size="small" className="btn">提现</Button>
                        </div>
                        : <div style={{ height: 20 }}></div>
                }
                <div className="card">
                    <div className="title">我的功能</div>
                    <div className="func" onClick={() => nav('info')}>
                        <div>
                            <img src={func1Img} style={{ marginRight: 5 }} />
                            <span>修改个人信息</span>
                        </div>
                        <img src={arrowImg} />
                    </div>

                    <Divider></Divider>

                    <div className="func" onClick={() => nav('changeMobile')}>
                        <div>
                            <img src={func2Img} style={{ marginRight: 5 }} />
                            <span>修改手机号</span>
                        </div>
                        <img src={arrowImg} />
                    </div>

                    <Divider></Divider>

                    <div className="func" onClick={() => {
                        window.location.href = 'https://work.weixin.qq.com/kfid/kfc628fa7604e5effe2';
                    }}>
                        <div>
                            <img src={func3Img} style={{ marginRight: 5 }} />
                            <span>联系客服</span>
                        </div>
                        <img src={arrowImg} />
                    </div>

                    <Divider></Divider>

                    <div className="func" onClick={() => {
                        Dialog.confirm({
                            title: "提示",
                            content: "确定要退出登录吗？",
                            onConfirm: async () => {
                                setUserInfo(undefined);
                                sessionStorage.clear();
                            }
                        });
                    }}>
                        <div>
                            <img src={func4Img} style={{ marginRight: 5 }} />
                            <span>退出登录</span>
                        </div>
                        <img src={arrowImg} />
                    </div>
                </div>
            </div>
            <TabBar safeArea defaultActiveKey={'mine'} onChange={onTabBarChange}>
                {tabs.map(item => (
                    <TabBar.Item key={item.key} icon={item.icon} title={item.title} />
                ))}
            </TabBar>
        </div>
    );
}