import { useEffect, useState } from "react";
import { IUserInfoRes, getUserInfo, login } from "../../apis/user";
import { Button, Dialog, Result, Toast } from "antd-mobile";
import { clearHistoryAndRedirect } from "../../utils/utils";

export function LoginPage() {
    // 用户信息
    const [userInfo, setUserInfo] = useState<IUserInfoRes>();

    const [status, setStatus] = useState(0);

    // 获取用户信息
    const initUserInfo = async () => {
        console.log('========获取用户信息');

        let userInfoRes = await getUserInfo();
        if (userInfoRes) {
            console.log(userInfoRes);

            let userInfo = userInfoRes.data;
            sessionStorage.setItem('user', JSON.stringify(userInfo));
            setUserInfo(userInfo);

            clearHistoryAndRedirect("/mine");
        } else {
            setStatus(-1);
        }

        Toast.clear();
    }

    const getInfoDialog = () => {
        getWeChatCode();

        // Dialog.show({
        //     content: '授权您的基础信息，用于快速登录注册服务',
        //     closeOnAction: true,
        //     actions: [
        //         [
        //             {
        //                 key: 'cancel',
        //                 text: '取消',
        //             },
        //             {
        //                 key: 'ok',
        //                 text: '授权',
        //                 bold: true,
        //                 danger: true
        //             },
        //         ],
        //     ],
        //     onAction: (action, index) => {
        //         if (action.key === 'ok') {
        //             getWeChatCode();
        //         } else {
        //             Toast.clear();
        //             setStatus(-1);
        //         }
        //     }
        // });
    }


    // 初始化
    useEffect(() => {
        let token = sessionStorage.getItem('token');
        if (!token) {
            let code = getUrlKeyVal('code');
            if (code) {
                if (getUrlKeyVal('test')) {
                    Toast.clear();
                    return;
                }
                // 换取token
                let scope = getUrlKeyVal('state') ?? '';
                let agentId = getUrlKeyVal('agent_id');
                Toast.show({
                    icon: 'loading',
                    content: '加载中',
                    duration: 0,
                    maskClickable: false
                });

                login({ code, scope, agent_id: agentId }).then(async (res) => {
                    // 存储token
                    sessionStorage.setItem('token', res.data.token);

                    initUserInfo();
                }).catch(e => {
                    setStatus(-1);
                })
            } else {
                getInfoDialog();
            }
        } else {
            initUserInfo();
        }
    }, []);

    // 请求通过微信官方接口 获取code
    function getWeChatCode(scope = 'snsapi_userinfo') {
        if (scope === 'snsapi_userinfo') sessionStorage.removeItem('token');
        // 获取当前页面地址作为回调地址
        let local = encodeURIComponent(window.location.href.split("?")[0]);
        console.log(local);
        let appid = 'wxf66b42beeac9bad5';// 自己填入测试号信息
        // 访问设置的回调地址 redirect_uri，会重定向显示带code参数的地址
        window.location.href = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" + appid +
            "&redirect_uri=" + local +
            `&response_type=code&scope=${scope}&state=${scope.split('_')[1]}#wechat_redirect`;
    }

    // 提取地址中的参数值
    function getUrlKeyVal(key: string) {
        let r = new RegExp(`[?|&]${key}=([^&;]+?)(&|#|;|$)`);
        let arr = r.exec(window.location.href) ?? [undefined, ''];
        let s = arr[1]!.replace(/\+/g, '%20');
        return decodeURIComponent(s) || undefined;
    }

    return <div>
        {
            status === 0 &&
            <Result
                status='waiting'
                title='正在登录'
                description='正在运行登录注册服务'
            />
        }
        {
            status === -1 &&
            <Result
                status='error'
                title='登录失败'
                description={
                    <Button onClick={getInfoDialog} style={{ marginTop: 30 }}>点击重新登录</Button>
                }
            />
        }
    </div>
}