export function Agreement() {
    return <div style={{padding: '0 20px'}}>
        <p style={{ textAlign: 'center' }}><strong>电签协议</strong></p>
        <p>本协议是佛山市<strong>友薪</strong>科技有限公司及其合作公司或子公司(具体公司名称以协议为准，以下称&ldquo;平台&rdquo;〉与您就电签合同的使用等相关事项所订立的有效合约。请您仔细阅读本协议(特别是以粗体下划线标注的内容)，如果您对本协议的条款有疑问，请通过平台客服渠道进行询问，平台将向您解释条款内容。如果您不同意本协议的任意内容，或者无法准确理解平台对条款的解释，请不要进行后续操作。您通过平台相关页面点击确认或以其他方式选择接受本协议，即表示您同意接受本协议的全部内容。</p>
        <p><strong>一、您的权利与义务</strong></p>
        <p>1.您自愿申请使用平台电签合同。对本服务如有疑问、建议或意见时，可拨打平台客服电话或网站进行咨询和投诉。</p>
        <p>2.您使用本服务时实施的所有行为均应当遵守国家法律、法规和各种社会公共利益或公共道德，并且遵守包括但不限于本协议及《服务协议》与相关的规则、附件以及相关业务条款。</p>
        <p>3.您使用本服务应当通过网站、微信服务号、客户端或合作商家网站，以及平台告知特定方式链接登录。</p>
        <p>4.您保证使用本服务过程中提供的资料真实、准确、完整、有效。平台按照您设置的相关信息为您提供相应服务，对于因您提供信息不真实或不完整所造成的损失由您自行承担。您确保正确填写个人信息，并且妥善保管预留信息，除使用本服务外，不要向任何其他人、其他网站、电话或短信的问询提供预留的信息内容。如您对合同信息内容有疑问的，请自行联系相关机构单位。如您使用的是他人的账户，请确保得到授权后再进行合同信息设置，否则因此给第三人或平台造成的任何损失，均由您自行承担。</p>
        <p>5.您保证使用本服务过程中使用的手机号、身份证、银行卡等为您本人所有或者已经得到持卡人有效授权，否则因此导致的责任均由您自行承担。一旦您点击确认签约，亦不得要求变更或撤销该指令。</p>
        <p><strong>二、平台的权利与义务</strong></p>
        <p>1.平台有权根据您工作情况，决定是否受理您的服务申诂，并且可以根据注册信息等相关要素对您从事签约的权限和身份进行核实。</p>
        <p>2.平台有权根据自身业务的发展，增加、减少或撤销签约服务项目，调整签约服务的内容，并且有权对本服务进行升级、改造。因您提供的信息不全等任何原因导致合同签署不成功的，平台将向您返回失败信息:因此导致您签约时间增</p>
        <p>长或任何其地操生的长或任何其他损失的，该损失应由您\自行完全承担。即便如该损失应中你自行完个争担，即便如</p>
        <p>此，平台向您返回的信息为签约成功，但因对应的服务机构单位的系统或操作导致该合同最终还是需要重新签署的，您同意完全承担该等重签时间，若您对重签有异议的，应当直接与对应的机构单位协商解决。</p>
        <p>3.平台应当在技术上确保整个网络签约平台的安全、有效、正常运行，保证您能够顺利使用本协议约定服务，并向您提供签约记录查询、签约状态等查询的服务。但不承担因通讯、停电故障、黑客攻击、相关机构单位出名单问题、不可抗力等非平台原因所引起交易中断、交易错误引起的责任。</p>
        <p>4.平台承诺不会以任何形式挪用您使用本服务的合同。</p>
        <p>5.平台可根据业务发展对提供本服务向您进行优化升级，但应当明示其变更，如您不接受相关服务调整及其变更，则不应继续使用本服务。一旦您继续使用，即视为您同意接受包括服务条款在内的所有约定。</p>
        <p>6.平台对您在使用本服务过程中填写的相关信息及历史使用记录负有保密义务，但您理解并同意，平台可以基于向您提供更优质服务的目的或其他平台产品而保留并使用您前述信息，或者向其他第三方披露该等信息。</p>
        <p><strong>三、差错处理</strong></p>
        <p>由于您未按规定操作，或者您自身其他原因造成签约指令未执行、未适当执行、延迟执行的，应及时通过拨打客服电话:4008-381-081或通过在线客服通知平台。平台应积极调查并告知您调查结果。</p>
        <p><strong>四、协议的中止和终止</strong></p>
        <p>1.如您在平台平台上存在违法行为或违反本协议的行为，或因您此前使用本服务的行为引发争议的，平台仍可行使追究的权利。</p>
        <p>2.您理解并同意，平台不对因下述任一情况而发生的服务中断或终止而承担任何赔偿责任</p>
        <p>(1)平台基于单方判断，认为您已经违反本服务条款的规定，将中断或终止向您提供部分或全部服务功能，并将您用户资料加以删除。</p>
        <p>(2)发现您注册资料虚假、异常签约或有疑义或有违法嫌疑时，平台不经通知有权先行中断或终止您平台账户、密码，并拒绝您使用部分或全部服务功能。</p>
        <p><strong>五、违约责任</strong></p>
        <p>本协议任何一方违反本协议规定的内容，给另一方造成损害的，应当承担违约责任，赔偿另一方因此而造成的直接损失。</p>
        <p><strong>六、免责条款</strong></p>
        <p>若您向电签合同系统提交的信息为无效或虚假信息，导致其签约的结果为签约失败。由此产生的损失，需由您自行承担。若您对此存在疑义，可与相应各机构单位进行协商，平台将尽可能协助。</p>
        <p><strong>七、法律适用及争议解决</strong></p>
        <p>本协议适用中华人民共和国大陆地区法律。就本协议的确认、履行或解释发生争议，平台与您应努力友好协商解决。如协商不成，双方同意由平台住所地法院管辖审理双方的纠纷或争议。</p>
    </div>
}