import { CompassOutline, FileOutline, UserOutline } from "antd-mobile-icons";

export const tabs = [
    {
        key: 'home',
        title: '接单广场',
        icon: (active: boolean) =>
            <CompassOutline style={{ color: active ? 'var(--adm-color-primary)' : '' }} />,
    },
    {
        key: 'order',
        title: '订单管理',
        icon: (active: boolean) =>
            <FileOutline style={{ color: active ? 'var(--adm-color-primary)' : '' }} />,
    },
    {
        key: 'mine',
        title: '我的',
        icon: (active: boolean) =>
            <UserOutline style={{ color: active ? 'var(--adm-color-primary)' : '' }} />,
    },
]