import { ChangeEvent, useEffect, useState } from "react";
import { Button, Form, Image, Input, Toast, SafeArea, SpinLoading, NoticeBar } from "antd-mobile";
import { IChangeUserInfoForm, changeUserInfo, getUserInfo } from "../../../apis/user";
import React from "react";
import { uploadFile } from "../../../apis/common";

import avatar from '@assets/default_avatar.svg';
import Compressor from "compressorjs";

export function UserInfoPage() {
    const loadUserInfo = async () => {
        let userInfoRes = await getUserInfo();
        if (userInfoRes) {
            console.log(userInfoRes);

            let userInfo = userInfoRes.data;
            setAvatarImg(userInfo.user_info?.avatar_link ?? undefined);
            form.setFieldsValue({
                avatar_id: userInfo.user_info?.avatar_id,
                nickname: userInfo.user_info?.nickname,
                bank_name: userInfo.user_info?.bank_name,
                bank_no: userInfo.user_info?.bank_no,
                bank_deposit: userInfo.user_info?.bank_deposit,
                bank_place: userInfo.user_info?.bank_place
            });
        }
    }

    useEffect(() => {
        loadUserInfo();
    }, []);

    const [form] = Form.useForm<IChangeUserInfoForm>();
    const onSubmit = async () => {
        try {
            await form.validateFields();
        } catch (error) {
            return;
        }

        const values = {
            ...form.getFieldsValue(true)
        };

        Toast.show({
            icon: 'loading',
            content: '正在保存',
            duration: 0
        });

        try {
            let res = await changeUserInfo(values);
            // 更新用户信息
            let userInfoRes = await getUserInfo();
            if (userInfoRes) {
                console.log(userInfoRes);

                let userInfo = userInfoRes.data;
                sessionStorage.setItem('user', JSON.stringify(userInfo));
            }

            Toast.clear();
            Toast.show({
                icon: 'success',
                content: res.msg,
                afterClose() {
                    window.location.href = '/mine';
                }
            });
        } catch (e) {
            console.error(e);
        }
    }

    // 上传头像
    const [avatarLoading, setAvatarLoading] = useState(false);
    const avatarRef = React.createRef<HTMLInputElement>();
    const [avatarImg, setAvatarImg] = useState<string>();
    const uploadAvatar = async (event: ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files) return;
        let files = event.target.files;
        setAvatarLoading(true);

        // 压缩
        new Compressor(files[0], {
            quality: 0.6,
            success(result) {
                let file = new File([result], files[0].name);
                uploadFile({ file: file, type: 'user_logo' }).then(res => {
                    setAvatarImg(res.data.full_link);
                    form.setFieldValue('avatar_id', res.data.file_id);
                    setAvatarLoading(false);
                }).catch(e => {
                    setAvatarLoading(false);
                });
            },
            error(err) {
                console.log(err.message);
                Toast.show({
                    icon: 'fail',
                    content: '压缩过程出现错误'
                });
                setAvatarLoading(false);
            },
        });
    };

    return <div>
        <Form
            form={form}
            footer={
                <Button block color='primary' onClick={onSubmit} size='large'>
                    提交
                </Button>
            }
        >
            <Form.Header>用户信息</Form.Header>

            <Form.Item label='上传头像'>
                <div style={{ display: 'flex', justifyContent: 'space-between', padding: 20 }}>
                    {
                        avatarLoading
                            ? <SpinLoading color='primary' style={{ width: "20vw" }} />
                            : <Image src={avatarImg ?? avatar} width="20vw" height="20vw" fit='cover'
                                onContainerClick={() => avatarRef.current?.click()}
                            />
                    }

                    <input
                        ref={avatarRef}
                        style={{ display: 'none' }}
                        type="file"
                        accept='image/jpeg,image/jpg,image/png'
                        onChange={uploadAvatar}
                    />

                </div>
            </Form.Item>

            <Form.Item
                name='nickname'
                label='昵称'
                rules={[{ required: true, message: '昵称不能为空' }]}
            >
                <Input placeholder='请输入昵称' />
            </Form.Item>

            <Form.Item
                name='bank_no'
                label='银行卡号'
                rules={[
                    { required: true, message: '银行卡号不能为空' },
                    {
                        pattern: /^([1-9]{1})(\d{14}|\d{15}|\d{16}|\d{17}|\d{18})$/,
                        message: '请输入正确的银行卡号',
                    },
                ]}
            >
                <Input placeholder='请输入银行卡号' />
            </Form.Item>

            <Form.Item
                name='bank_name'
                label='银行名称'
                rules={[{ required: true, message: '银行名称不能为空' }]}
            >
                <Input placeholder='请输入银行名称' />
            </Form.Item>

            <Form.Item
                name='bank_deposit'
                label='开户支行'
                rules={[{ required: true, message: '开户支行不能为空' }]}
            >
                <Input placeholder='请输入开户支行' />
            </Form.Item>

            <Form.Item
                name='bank_place'
                label='开户地'
                rules={[{ required: true, message: '开户地不能为空' }]}
            >
                <Input placeholder='请输入开户地' />
            </Form.Item>
        </Form>

        <SafeArea position='bottom' />
    </div>
}
