import { axiosInstance, CommonResponse } from ".";

export interface ILoginForm {
    code: string,
    scope: string,
    agent_id?: string
}

export interface ILoginData {
    token: string,
    openid: string
}

/**
 * @description: 用户登录
 * @params {ILoginForm} params
 * @return {Promise}
 */
export const login = (params: ILoginForm): Promise<CommonResponse<ILoginData>> => {
    return axiosInstance.get('/h5/oauth/login', { params }).then(res => res.data);
};

export interface IUserInfoRes {
    user_info?: {
        id?: number,
        company_id?: number,
        sp_id?: number,
        real_name?: string,
        mobile?: string,
        idcard?: string,
        bank_no?: string,
        bank_name?: string,
        bank_deposit?: string,
        bank_place?: string,
        nickname?: string,
        avatar_id: number,
        avatar_link: string,
    },
    oauth_user: {
        id: number,
        openid: string,
        nickname: string,
        headimgurl: string,
        is_auth: boolean,
        auth_expired_at: string
    },
    is_user_auth: boolean,
    is_user_sign: boolean
}

/**
 * @description: 获取用户信息
 * @returns {Promise}
 */
export const getUserInfo = (): Promise<CommonResponse<IUserInfoRes>> => {
    return axiosInstance.get('/h5/user/info').then(res => res.data);
};

export interface IRegisterForm {
    real_name: string,
    mobile: string,
    code: number,
    idcard: string,
    sex?: number,
    nation_id?: number,
    birthday?: string,
    address?: string,
    idcard_front_img: number,
    idcard_back_img: number,
    is_idcard_long_time: boolean,
    idcard_begin_date: string,
    idcard_expire_date: string,
    bank_no: string,
    bank_name: string,
    bank_deposit: string,
    bank_place: string
}

/**
 * 注册用户信息
 */
export const register = (data: IRegisterForm): Promise<CommonResponse> => {
    return axiosInstance.post('/h5/user/register', data).then(res => res.data);
};

export interface IRegisterCompany {
    attach_logo_link: string,
    company_id: number,
    company_name: string,
    sp_id: number,
    sp_name: string,
    name: string
}

export const getRegisterCompany = (data: { company_id?: number, sp_id?: number }): Promise<CommonResponse<IRegisterCompany>> => {
    return axiosInstance.post('/h5/user/default_company', data).then(res => res.data);
};

export interface IFddAuth {
    is_auth: boolean,
    auth_url: string
}

/**
 * 获取法大大权限
 */
export const getFddAuth = (): Promise<CommonResponse<IFddAuth>> => {
    return axiosInstance.get('/h5/contract/person_auth_info').then(res => res.data);
};


export interface IRegisterContract {
    list: {
        id: number,
        contract_id: string,
        title: string,
        sign_type: number,
        created_at: string,
        initiator_openid: string,
        is_success: boolean,
        sign_type_text: string
    }[],
    batch_sign_url: string
}

/**
 * 获取注册时的签约合同
 */
export const getRegisterContract = (): Promise<CommonResponse<IRegisterContract>> => {
    return axiosInstance.post('/h5/contract/register_contract').then(res => res.data);
};

export interface IMobileLoginForm {
    mobile: string,
    code: number
}

export interface IMobileLoginRes {
    token: string,
}

/**
 * 手机号登录
 */
export const mobileLogin = (data: IMobileLoginForm): Promise<CommonResponse<IMobileLoginRes>> => {
    return axiosInstance.post('/h5/user/login', data).then(res => res.data);
};


export interface IChangeUserInfoForm {
    avatar_id: number,
    nickname: string,
    bank_name: string,
    bank_no: string,
    bank_deposit: string,
    bank_place: string
}
/**
 * 修改个人资料
 */
export const changeUserInfo = (data: IChangeUserInfoForm): Promise<CommonResponse> => {
    return axiosInstance.post('/h5/user/edit_info', data).then(res => res.data);
};

/**
 * 修改手机号
 */
export const changeMobile = (data: IMobileLoginForm): Promise<CommonResponse> => {
    return axiosInstance.post('/h5/user/change_mobile', data).then(res => res.data);
};