import { axiosInstance, CommonResponse, IPager } from ".";

export interface ITaskListSearchForm extends IPager {
    keywords?: string,
    province_id?: number,
    city_id?: number,
    area_id?: number,
    category?: number[]
}

export interface ITaskListData {
    id: number,
    company_id: number,
    sp_id: number,
    title: string,
    money: number,
    finish_at: string,
    company: {
        id: number,
        name: string
    },
    service_provider: {
        id: number,
        name: string,
        region_text?: string
    }
}

interface ITaskList {
    list: ITaskListData[],
    total: number
}

export const getTaskList = (data: ITaskListSearchForm): Promise<CommonResponse<ITaskList>> => {
    return axiosInstance.post('/h5/home/task', data).then(res => res.data);
};

export interface ITaskDetailDetail {
    id: number,
    company: {
        id: number,
        name: string
    },
    sp_id: number,
    title: string,
    province_id: number,
    city_id: number,
    area_id: number,
    address?: string,
    money: number,
    finish_at: string,
    content: string,
    attach_files: number[],
    category: number[],
    province_text: string,
    city_text: string,
    area_text: string,
    category_info: {
        id: number,
        name: string
    }[],
    attach_files_info: {
        id: number,
        attach_name: string,
        disk: string,
        attach_url: string,
        suffix: string,
        attach_url_link: string
    }[]
}


export interface ITaskDetail {
    detail: ITaskDetailDetail
}

export const getTaskDetail = (data: { task_id: number }): Promise<CommonResponse<ITaskDetail>> => {
    return axiosInstance.post('/h5/home/task_detail', data).then(res => res.data);
};


/**
 * 接单
 */
export const orderTask = (data: { task_id: number }): Promise<CommonResponse> => {
    return axiosInstance.post('/h5/order/taking_order', data).then(res => res.data);
};
