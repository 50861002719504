import { Divider, Space, Image, ImageViewer, Skeleton } from "antd-mobile";

import './detail.scss';
import { useEffect, useState } from "react";
import { ITaskDetailDetail, getTaskDetail } from "../../../../apis/home";

export function OrderDetailPage({ id }: { id: number }) {
    const [visible, setVisible] = useState(false);
    const [index, setIndex] = useState(0);

    const [detail, setDetail] = useState<ITaskDetailDetail>();


    useEffect(() => {
        getTaskDetail({ task_id: id }).then(res => {
            setDetail(res.data.detail);
        })
    }, [id]);

    return (
        detail ?
            <div className="order-detail">
                <div className="title">{detail.title}-{detail.company?.name}</div>
                <Divider />
                <div className="sub-title">订单地点</div>
                <div className="content">{detail.address ?? '无'}</div>
                <div className="sub-title">订单内容</div>
                <div className="content">{detail.content}</div>
                <div className="sub-title">订单附件</div>
                {
                    detail.attach_files_info && detail.attach_files_info.length > 0
                        ? <div>
                            <Space wrap>
                                {
                                    detail.attach_files_info.map((e, i) => (
                                        <Image src={e.attach_url_link} width={100} height={100} fit='cover' onContainerClick={() => {
                                            setIndex(i);
                                            setVisible(true);
                                        }} />
                                    ))
                                }
                            </Space>
                            {
                                visible &&
                                <ImageViewer.Multi
                                    images={detail.attach_files_info.map(e => e.attach_url_link)}
                                    visible={true}
                                    defaultIndex={index}
                                    onClose={() => {
                                        setVisible(false)
                                    }}
                                />
                            }
                        </div>
                        : <div>无</div>
                }

            </div>
            : <Skeleton.Paragraph lineCount={5} animated />
    )
}