import { useEffect, useMemo, useState } from "react";

import { Swiper, TabBar, Image, SearchBar, Picker, Toast, Card, Divider, Button, Space, NavBar, PullToRefresh, ErrorBlock, Skeleton, InfiniteScroll, DotLoading, Cascader, CascaderOption, Popup, Dialog } from "antd-mobile";
import { AppstoreOutline, CloseCircleOutline, DownOutline, TextOutline } from "antd-mobile-icons";

import { tabs } from "../index";
import './home.scss';
import { useNavigate } from "react-router-dom";
import { ITaskListData, ITaskListSearchForm, getTaskList, orderTask } from "../../../apis/home";
import { IDictMultiDataOption, getDictMultiList, getRegionByPid } from "../../../apis/common";
import { PickerColumn } from "antd-mobile/es/components/picker";
import { CascaderValue, CascaderValueExtend } from "antd-mobile/es/components/cascader";
import { CheckListValue } from "antd-mobile/es/components/check-list";
import { OrderDetailPage } from "./actions/detail";
import { IUserInfoRes } from "../../../apis/user";

import flagImg from '@assets/icon/flag.png';
import buildingImg from '@assets/icon/building.png';
import bannerImg from '@assets/image/banner.png';

export function HomePage() {
    const navigate = useNavigate();
    const onTabBarChange = (key: string) => {
        switch (key) {
            case 'order':
                navigate('/order');
                break;
            case 'mine':
                navigate('/mine');
                break;

            default:
                break;
        }
    };

    // 加载列表数据
    const [isTaskListLoading, setIsTaskListLoading] = useState(true);
    const [isTaskListError, setIsTaskListError] = useState(false);
    const [form, setForm] = useState<ITaskListSearchForm>({
        page: 1,
        size: 20,
    });
    const [taskList, setTaskList] = useState<ITaskListData[]>([]);
    const loadData = async (isRefresh: boolean = false, newForm?: ITaskListSearchForm) => {
        setIsTaskListLoading(true);

        let reqForm = newForm ?? form;

        if (isRefresh) {
            reqForm['page'] = 1;
        }

        try {
            let res = await getTaskList(reqForm);
            if (isRefresh) {
                setTaskList([...res.data.list]);
            } else {
                setTaskList(data => [...data, ...res.data.list]);
            }
            if (res.data.list.length < reqForm.size) {
                setHasMore(false);
            }
            setForm({
                ...reqForm,
                page: reqForm.page + 1,
            })
        } catch (e) {
            console.error(e);
            setIsTaskListError(true);
        }

        setIsTaskListLoading(false);
    };

    // 下拉刷新
    const onRefresh = async () => {
        loadData(true);
    };

    // 无限滚动
    const [hasMore, setHasMore] = useState(true);
    const InfiniteScrollContent = ({ hasMore }: { hasMore?: boolean }) => {
        return (
            <>
                {hasMore ? (
                    <>
                        <span>加载中</span>
                        <DotLoading />
                    </>
                ) : taskList.length > 0 ? (
                    <span>--- 没有更多数据 ---</span>
                ) : null}
            </>
        )
    }

    // 类型筛选
    const [typeList, setTypeList] = useState<IDictMultiDataOption[]>([]);
    const [typeText, setTypeText] = useState<string[]>([]);
    useEffect(() => {
        getDictMultiList(['task_category']).then((res) => {
            setTypeList([...res.data.task_category!.options]);
        });
    }, []);
    const showOrderTypePicker = async () => {
        const value = await Picker.prompt({
            columns: (value) => {
                let index = value.length > 0 ? typeList.findIndex(item => {
                    return item.id === value[0];
                }) : 0;
                return (
                    [
                        [...typeList.map(e => ({ label: e.name, value: e.id }))],
                        [...typeList[index].children.map(e => ({ label: e.name, value: e.id }))],
                    ]
                );
            },
        });

        if (value) {
            for (let item of typeList) {
                if (item.id === value[0]) {
                    setTypeText([item.name, item.children.find(e => e.id === value[1])!.name]);
                    break;
                }
            }

            setForm((data) => {
                let newData = {
                    ...data,
                    category: value.map(e => Number(e)),
                };
                loadData(true, newData);
                return newData;
            });
        }
    }
    const onClearTypeSearch = (e: any) => {
        e.stopPropagation();
        setForm((data) => {
            let newData = {
                ...data,
                category: undefined,
            };
            loadData(true, newData);
            return newData;
        });
    }

    // 地区筛选
    const [locationText, setLocationText] = useState<string[]>([]);
    const [locationPickerVisible, setLocationPickerVisible] = useState(false);
    const [valueToOptions, setValueToOptions] = useState(
        {} as Record<string, CascaderOption[] | null>
    )
    const options = useMemo<CascaderOption[]>(() => {
        function generate(v: string): CascaderOption[] | undefined {
            const options = valueToOptions[v]

            if (options === null) {
                return undefined
            }
            if (options === undefined) {
                return Cascader.optionSkeleton
            }
            return options.map(option => ({
                ...option,
                children: generate(option.value!),
            }));
        }

        return generate('0') ?? []
    }, [valueToOptions])
    async function fetchOptionsForValue(v: string, level: number) {
        if (v in valueToOptions) return
        if (level >= 3) {
            setValueToOptions(prev => ({
                ...prev,
                [v]: null,
            }))
            return
        }
        const data = (await getRegionByPid({ pid: Number(v) })).data?.list
        const options =
            data === null
                ? null
                : data.map(entry => ({
                    value: String(entry.id),
                    label: entry.name,
                }))
        setValueToOptions(prev => ({
            ...prev,
            [v]: options,
        }))
    }
    const showLocationPicker = async () => {
        setLocationPickerVisible(true);
    }
    useEffect(() => {
        fetchOptionsForValue('0', 0)
    }, [])
    const onLocationPickerConfirm = (value: CheckListValue[], extend: CascaderValueExtend) => {
        console.log(value, extend);
        if (value.length === 3) {
            setLocationText(extend.items.map(item => item!.label!));
            setForm((data) => {
                let newData = {
                    ...data,
                    province_id: Number(value[0]),
                    city_id: Number(value[1]),
                    area_id: Number(value[2]),
                };
                loadData(true, newData);
                return newData;
            });
        }
    }
    const onClearLocationSearch = (e: any) => {
        e.stopPropagation();
        setForm((data) => {
            let newData = {
                ...data,
                province_id: undefined,
                city_id: undefined,
                area_id: undefined,
            };
            loadData(true, newData);
            return newData;
        });
    }

    // 查看详情
    const [visible1, setVisible1] = useState(false)
    const [detailId, setDetailId] = useState<number>();

    // 接单
    const toOrder = (id: number) => {
        let userStr = sessionStorage.getItem("user");
        if (userStr) {
            let user: IUserInfoRes = JSON.parse(userStr);
            if (!user.is_user_auth) {
                Dialog.confirm({
                    title: "提示",
                    content: "您尚未注册信息，前往登记吗？",
                    onConfirm: async () => {
                        window.location.href = '/register';
                    }
                });
                return;
            }
            if (!user.is_user_sign) {
                Dialog.confirm({
                    title: "提示",
                    content: "您尚未签约，前往签约吗？",
                    onConfirm: async () => {
                        window.location.href = '/sign';
                    }
                });
                return;
            }
        } else {
            Dialog.confirm({
                title: "提示",
                content: "您尚未登录，前往登录吗？",
                onConfirm: async () => {
                    window.location.href = '/login';
                }
            });
            return;
        }

        Dialog.confirm({
            title: "注意！",
            content: "确认代表您已经同意该订单的服务内容佣金等相关内容事项！如未清晰详情，请查看详情后，再接单。",
            onConfirm: async () => {
                Toast.show({
                    icon: 'loading',
                    content: '加载中…',
                    duration: 0,
                })
                try {
                    let res = await orderTask({ task_id: id });
                    Toast.clear();
                    Toast.show({
                        icon: 'success',
                        content: res.msg,
                        afterClose() {
                            window.location.href = '/order';
                        }
                    });
                } catch (e) {
                    console.error(e);
                }
            }
        });
    }

    return (
        <div className="home">
            <div className="content">
                {/* 轮播图 */}
                <Swiper
                    loop
                    autoplay
                >
                    {
                        [1].map(i => (
                            <Swiper.Item key={i}>
                                <Image src={bannerImg} fit='cover' className="swiper-item" />
                            </Swiper.Item>
                        ))
                    }
                </Swiper>

                {/* 搜索框 */}
                <div className="search_bar">
                    <SearchBar placeholder='请输入要搜索的内容' clearable onSearch={(e) => {
                        setForm((data) => {
                            let newData = {
                                ...data,
                                keywords: e,
                            };
                            loadData(true, newData);
                            return newData;
                        });
                    }}
                        onClear={() => {
                            setForm((data) => {
                                let newData = {
                                    ...data,
                                    keywords: undefined,
                                };
                                loadData(true, newData);
                                return newData;
                            });
                        }}
                    />
                </div>

                {/* 搜索工具栏 */}
                <div className="search_tools">
                    <div onClick={showOrderTypePicker}>{form.category ? typeText.join(' - ') : "订单类型"}
                        <span style={{ marginLeft: 4 }}>{form.category ? <CloseCircleOutline onClick={onClearTypeSearch} /> : <DownOutline />}</span>
                    </div>
                    <div onClick={showLocationPicker}>
                        {form.area_id ? locationText.join(' - ') : '选择地区'}
                        <span style={{ marginLeft: 4 }}>{form.area_id ? <CloseCircleOutline onClick={onClearLocationSearch} /> : <DownOutline />}</span>
                        <Cascader
                            visible={locationPickerVisible}
                            onClose={() => {
                                setLocationPickerVisible(false)
                            }}
                            options={options}
                            onSelect={value => {
                                value.forEach((v, index) => {
                                    fetchOptionsForValue(String(v), index + 1)
                                })
                            }}
                            onConfirm={onLocationPickerConfirm}
                        />
                    </div>
                </div>

                <div className="list">
                    <div className="scroll-y">
                        <PullToRefresh
                            onRefresh={onRefresh}
                        >
                            {
                                !isTaskListLoading ?
                                    !isTaskListError ?
                                        taskList.length > 0 ?
                                            taskList.map(task => (
                                                <div className="item" key={task.id}>
                                                    <Card
                                                        style={{ borderRadius: '10px' }}
                                                    >
                                                        <div className="title"><img src={flagImg} className="icon" /><span>{task.title}</span></div>
                                                        <div className="company"><img src={buildingImg} className="icon" /><span>{task.company.name} - {task.service_provider.name}</span></div>
                                                        <Divider />
                                                        <div className="bottoms">
                                                            <Button
                                                                color='primary'
                                                                fill='outline'
                                                                style={{ borderRadius: 20, padding: '5px 15px' }}
                                                                onClick={() => {
                                                                    setDetailId(task.id);
                                                                    setVisible1(true);
                                                                }}>
                                                                查看详情
                                                            </Button>

                                                            <Button
                                                                color='primary'
                                                                fill='solid'
                                                                style={{ borderRadius: 20, padding: '5px 15px' }}
                                                                onClick={() => toOrder(task.id)}>
                                                                接单
                                                            </Button>
                                                        </div>
                                                    </Card>
                                                </div>
                                            ))
                                            : <ErrorBlock status='empty' />
                                        : <ErrorBlock status='default' />
                                    : <Skeleton.Paragraph lineCount={5} animated style={{ padding: 10 }} />
                            }

                        </PullToRefresh>
                        <InfiniteScroll loadMore={() => loadData()} hasMore={hasMore}>
                            <InfiniteScrollContent hasMore={hasMore} />
                        </InfiniteScroll>
                    </div>
                </div>

                {
                    detailId && visible1 &&
                    <Popup
                        visible={true}
                        onMaskClick={() => {
                            setVisible1(false)
                        }}
                        onClose={() => {
                            setVisible1(false)
                        }}
                        showCloseButton
                    >
                        <div
                            style={{ height: '80vh', overflowY: 'scroll' }}
                        >
                            <OrderDetailPage id={detailId} />
                        </div>
                    </Popup>
                }
            </div>
            <TabBar safeArea defaultActiveKey={'home'} onChange={onTabBarChange}>
                {tabs.map(item => (
                    <TabBar.Item key={item.key} icon={item.icon} title={item.title} />
                ))}
            </TabBar>
        </div>
    );
}