import { CommonResponse, IPager, axiosInstance } from ".";

export interface IOrderListSearchForm extends IPager {
    nav: number
}

export interface IOrderListData {
    id: number,
    company_id: number,
    service_provider_id: number,
    task_id: number,
    status: number,
    show_status_text: string,
    taking_at: string,
    finish_at: string,
    settle_at?: string,
    is_upload_achievement?: boolean,
    company: {
        id: number,
        name: string
    },
    service_provider: {
        id: number,
        name: string,
        region_text: null
    },
    task: {
        id: number,
        title: string,
        money: number
    }
}

export interface IOrderList {
    list: IOrderListData[],
    total: number
}

export const getOrderList = (data: IOrderListSearchForm): Promise<CommonResponse<IOrderList>> => {
    return axiosInstance.post('/h5/order/my_list', data).then(res => res.data);
};

export interface IFinishOrderForm {
    order_id: number,
    finish_content: string,
    finish_files: number[]
}

export const finishOrder = (data: IFinishOrderForm): Promise<CommonResponse> => {
    return axiosInstance.post('/h5/order/finish', data).then(res => res.data);
};

/**
 * 放弃订单
 */
export const cancelOrder = (data: { order_id: number }): Promise<CommonResponse> => {
    return axiosInstance.post('/h5/order/cancel', data).then(res => res.data);
};