import { Toast } from 'antd-mobile';
import axios, { AxiosInstance, AxiosResponse } from 'axios';
import { generateRandomString, jsonSort } from '../utils/utils';
import { MD5 } from 'crypto-js';
import httpBuildQuery from 'http-build-query';

// 返回res.data的interface
export interface CommonResponse<T = any> {
    "code": number,
    "msg": string,
    "data": T
}

export let axiosInstance: AxiosInstance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    withCredentials: false,
});

// axios实例拦截响应
axiosInstance.interceptors.response.use(
    (response: AxiosResponse) => {
        // 更新token
        if (response.headers.Token) {
            sessionStorage.setItem('app_token', response.headers.Token);
        }


        // 请求成功
        if (response.status === 200) {
            if (response.data.code === 200) {
                return response;
            }

            // token失效
            if (response.data.code === 401) {
                sessionStorage.removeItem('token');
                window.location.href = '/login';
            }

            // 错误提示
            Toast.clear();
            Toast.show({
                content: response.data.msg,
                duration: 3000,
                position: 'bottom',
            });

            return Promise.reject(response);
        }

        // 状态码错误
        Toast.clear();
        Toast.show({
            content: response.status,
            duration: 3000,
            position: 'bottom',
        });

        return Promise.reject(response);
    },
    // 请求失败
    (error: any) => {
        const { response } = error;
        if (response) {
            // 请求已发出，但是不在2xx的范围
            Toast.clear();
            Toast.show({
                content: response.status,
                duration: 3000,
                position: 'bottom',
            })
            return Promise.reject(response);
        } else {
            Toast.clear();
            Toast.show({
                content: '网络连接异常，请稍后再试！',
                duration: 3000,
                position: 'bottom',
            })
        }
    }
);

const appid = 'YXgXEx7E9k';
const appKey = 'Ds0T6CBcoohTRDYoIRvzknJantmOsDv0';

// axios实例拦截请求
axiosInstance.interceptors.request.use(
    (config) => {
        config.headers.Appid = appid;
        config.headers.Nonce = generateRandomString(32);
        config.headers.Timestamp = Math.floor(Date.now() / 1000);

        let _data: any = {
            appid: config.headers.Appid,
            nonce: config.headers.Nonce,
            timestamp: config.headers.Timestamp,
        };
        if (config.data) {
            _data = {
                ..._data,
                ...config.data,
            }
        }
        if (config.params) {
            _data = {
                ..._data,
                ...config.params,
            }
        }
        _data = jsonSort(_data);

        const rfcFormattedQuery = httpBuildQuery(_data);

        // console.log('数据: ', rfcFormattedQuery);
        // console.log('加密前: ', rfcFormattedQuery + appKey);

        let md5 = MD5(rfcFormattedQuery + appKey);

        // console.log('加密后: ', md5.toString().toUpperCase());

        config.headers.Signature = md5.toString().toUpperCase();

        const token = sessionStorage.getItem('token');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`
        }

        return config;
    },
    (error: any) => {
        return Promise.reject(error);
    }
)

export interface IPager {
    page: number,
    size: number
}