import { ChangeEvent, useEffect, useState } from "react";
import { IRegisterCompany, IRegisterForm, getRegisterCompany, register } from "../../apis/user";
import { Button, Form, Space, Image, Input, Toast, Radio, SafeArea, Picker, DatePicker, SpinLoading, Avatar, Checkbox, Popup, NoticeBar } from "antd-mobile";

import idCardBack from '@assets/idcard_back.png';
import idCardFront from '@assets/idcard_front.png';
import { IDictData, codeType, getDictList, getIdcardOcrData, sendVCode, uploadFile } from "../../apis/common";
import dayjs from "dayjs";
import React from "react";
import Compressor from "compressorjs";
import { clearHistoryAndRedirect } from "../../utils/utils";
import { Agreement } from "../agreement/agreement";

import './register.scss';

export function RegisterPage() {
    // 提取地址中的参数值
    function getUrlKeyVal(key: string) {
        let r = new RegExp(`[?|&]${key}=([^&;]+?)(&|#|;|$)`);
        let arr = r.exec(window.location.href) ?? [undefined, ''];
        let s = arr[1]!.replace(/\+/g, '%20');
        return decodeURIComponent(s) || undefined;
    }

    const [company, setCompany] = useState<IRegisterCompany>();

    useEffect(() => {
        let company_id = getUrlKeyVal('c_id') ? Number(getUrlKeyVal('c_id')) : undefined;
        let sp_id = getUrlKeyVal('sp_id') ? Number(getUrlKeyVal('sp_id')) : undefined;

        getRegisterCompany({ company_id, sp_id }).then(res => {
            setCompany(res.data);
        });
    }, []);

    const [form] = Form.useForm<IRegisterForm>();
    const onSubmit = async () => {
        try {
            await form.validateFields();
        } catch (error) {
            return;
        }

        const values = {
            ...form.getFieldsValue(true)
        };

        if (values.idcard_begin_date) values.idcard_begin_date = dayjs(values.idcard_begin_date).format('YYYY-MM-DD');
        if (values.idcard_expire_date) values.idcard_expire_date = dayjs(values.idcard_expire_date).format('YYYY-MM-DD');
        if (values.nation_id) values.nation_id = values.nation_id[0];
        if (values.birthday) values.birthday = dayjs(values.birthday).format('YYYY-MM-DD');

        Toast.show({
            icon: 'loading',
            content: '正在提交',
            duration: 0
        });

        try {
            let res = await register(values);
            Toast.clear();
            Toast.show({
                icon: 'success',
                content: res.msg,
                afterClose() {
                    clearHistoryAndRedirect('/sign');
                }
            })
        } catch (e) {
            console.error(e);
        }
    }

    const [counter, setCounter] = useState(0);
    const send = async () => {
        if (counter > 0) return;

        try {
            let { mobile } = await form.validateFields(['mobile']);

            setCounter(60);

            let counterId = setInterval(() => {
                setCounter(data => {
                    if (data > 0) {
                        return data - 1;
                    } else {
                        clearInterval(counterId);
                        return 0;
                    }
                });
            }, 1000);

            let res = await sendVCode({
                mobile,
                type: codeType.register
            });

            Toast.show({
                content: res.msg,
            });
        } catch (e) {
            console.error(e);
        }
    }

    const [sex, setSex] = useState<IDictData>();
    const [nation, setNation] = useState<IDictData>();
    useEffect(() => {
        getDictList(['global_sex', 'sys_nation']).then(res => {
            setSex(res.data.global_sex);
            setNation(res.data.sys_nation);
        });
    }, []);

    // 上传身份证国徽面
    const [inputIdcardFrontLoading, setInputIdcardFrontLoading] = useState(false);
    const inputIdcardFrontRef = React.createRef<HTMLInputElement>();
    const [inputIdcardFrontImg, setInputIdcardFrontImg] = useState<string>();
    const uploadIdcardFront = async (event: ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files) return;
        let files = event.target.files;
        setInputIdcardFrontLoading(true);

        // 压缩
        new Compressor(files[0], {
            quality: 0.6,
            success(result) {
                let file = new File([result], files[0].name);
                uploadFile({ file: file, type: 'idcard' }).then(res => {
                    setInputIdcardFrontImg(res.data.full_link);
                    form.setFieldValue('idcard_front_img', res.data.file_id);
                    Toast.show({
                        icon: 'loading',
                        content: '正在加载数据',
                        duration: 0
                    });
                    getIdcardOcrData({ fid: res.data.file_id }).then(res => {
                        let data = res.data;
                        form.setFieldsValue({
                            is_idcard_long_time: data.is_long_time,
                            idcard_begin_date: dayjs(data.valid_date_start).toDate() as any,
                            idcard_expire_date: dayjs(data.valid_date_end).toDate() as any,
                        });
                    });

                    setInputIdcardFrontLoading(false);
                    Toast.clear();
                }).catch(e => {
                    Toast.clear();
                });
            },
            error(err) {
                console.log(err.message);
                Toast.show({
                    icon: 'fail',
                    content: '压缩过程出现错误'
                });
                setInputIdcardFrontLoading(false);
            },
        });
    };
    // 上传身份证人像面
    const [inputIdcardBackLoading, setInputIdcardBackLoading] = useState(false);
    const inputIdcardBackRef = React.createRef<HTMLInputElement>();
    const [inputIdcardBackImg, setInputIdcardBackImg] = useState<string>();
    const uploadIdcardBack = async (event: ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files) return;
        let files = event.target.files;
        setInputIdcardBackLoading(true);

        // 压缩
        new Compressor(files[0], {
            quality: 0.6,
            success(result) {
                let file = new File([result], files[0].name);
                uploadFile({ file: file, type: 'idcard' }).then(res => {
                    setInputIdcardBackImg(res.data.full_link);
                    form.setFieldValue('idcard_back_img', res.data.file_id);

                    Toast.show({
                        icon: 'loading',
                        content: '正在加载数据',
                        duration: 0
                    });
                    getIdcardOcrData({ fid: res.data.file_id }).then(res => {
                        let data = res.data;
                        form.setFieldsValue({
                            real_name: data.name,
                            sex: data.sex_code,
                            idcard: data.id_num,
                            is_idcard_long_time: data.is_long_time,
                            nation_id: [data.nation_code] as any,
                            birthday: dayjs(data.birth).toDate() as any,
                            address: data.address
                        });
                        Toast.clear();
                    }).catch(e => {
                        Toast.clear();
                    });
                    setInputIdcardBackLoading(false);
                });
            },
            error(err) {
                console.log(err.message);
                Toast.show({
                    icon: 'fail',
                    content: '压缩过程出现错误'
                });
                setInputIdcardBackLoading(false);
            },
        });
    };

    const [visible, setVisible] = useState(false)

    return <div className="register">
        <NoticeBar content={<div style={{ color: 'black' }}>已有账号？<span style={{ color: "blue", textDecorationLine: 'underline' }}>点击此处绑定</span></div>} color='alert' icon={false} onClick={() => {
            window.location.href = '/bind';
        }} />

        <Form
            form={form}
            footer={
                inputIdcardFrontImg && inputIdcardBackImg &&
                <Button block color='primary' onClick={onSubmit} size='large'>
                    提交
                </Button>
            }
        >
            <Form.Header>认证注册</Form.Header>

            <div style={{ display: 'flex', padding: 20, alignItems: 'center' }}>
                <Avatar src={company?.attach_logo_link ?? ''} />
                <div style={{ marginLeft: 20, fontSize: 16 }}>{company?.company_name ?? '企业'} - {company?.sp_name ?? '服务商'}</div>
            </div>

            <Form.Item label='上传身份证'>
                <div style={{ display: 'flex', justifyContent: 'space-around', padding: 20 }}>
                    {
                        inputIdcardBackLoading
                            ? <SpinLoading color='primary' style={{ width: "35vw" }} />
                            : <Image src={inputIdcardBackImg ?? idCardBack} width="35vw" height="35vw" fit='cover'
                                onContainerClick={() => inputIdcardBackRef.current?.click()}
                            />
                    }
                    <input
                        ref={inputIdcardBackRef}
                        style={{ display: 'none' }}
                        type="file"
                        accept='image/jpeg,image/jpg,image/png'
                        onChange={uploadIdcardBack}
                    />

                    {
                        inputIdcardFrontLoading
                            ? <SpinLoading color='primary' style={{ width: "35vw" }} />
                            : <Image src={inputIdcardFrontImg ?? idCardFront} width="35vw" height="35vw" fit='cover'
                                onContainerClick={() => inputIdcardFrontRef.current?.click()}
                            />
                    }

                    <input
                        ref={inputIdcardFrontRef}
                        style={{ display: 'none' }}
                        type="file"
                        accept='image/jpeg,image/jpg,image/png'
                        onChange={uploadIdcardFront}
                    />

                </div>
            </Form.Item>

            {
                inputIdcardFrontImg && inputIdcardBackImg &&
                <>
                    <Form.Item
                        name='real_name'
                        label='姓名'
                        rules={[{ required: true, message: '姓名不能为空' }]}
                    >
                        <Input placeholder='请输入姓名' />
                    </Form.Item>

                    <Form.Item
                        name='idcard'
                        label='身份证号码'
                        rules={[
                            { required: true, message: '身份证号码不能为空' },
                            {
                                pattern: /^[1-9]\d{5}(19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/,
                                message: '请输入正确的身份证号',
                            },
                        ]}
                    >
                        <Input placeholder='请输入身份证号码' maxLength={18} />
                    </Form.Item>

                    {/* <Form.Item
                        label="身份证是否长期"
                        name="is_idcard_long_time"
                        rules={[{ required: true, message: '请选择身份证是否长期' }]}
                    >
                        <Radio.Group>
                            <Space direction='vertical'>
                                <Radio key={1} value={true as any}>长期身份证</Radio>
                                <Radio key={2} value={false as any}>非长期身份证</Radio>
                            </Space>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, curValues) => prevValues.is_idcard_long_time !== curValues.is_idcard_long_time}
                    >
                        {({ getFieldValue, resetFields }) => {
                            let isLongTime = getFieldValue('is_idcard_long_time');
                            return (
                                <>
                                    <Form.Item
                                        name='idcard_begin_date'
                                        label='身份证有效期开始时间'
                                        trigger='onConfirm'
                                        rules={[{ required: true, message: '身份证有效期开始时间不能为空' }]}
                                        onClick={(e, datePickerRef) => {
                                            datePickerRef.current?.open()
                                        }}
                                    >
                                        <DatePicker>
                                            {value =>
                                                value ? dayjs(value).format('YYYY-MM-DD') : '请身份证有效期开始时间'
                                            }
                                        </DatePicker>
                                    </Form.Item>
                                    {
                                        !isLongTime &&
                                        <Form.Item
                                            name='idcard_expire_date'
                                            label='身份证有效期结束时间'
                                            trigger='onConfirm'
                                            rules={[{ required: true, message: '身份证有效期结束时间不能为空' }]}
                                            onClick={(e, datePickerRef) => {
                                                datePickerRef.current?.open()
                                            }}
                                        >
                                            <DatePicker>
                                                {value =>
                                                    value ? dayjs(value).format('YYYY-MM-DD') : '请身份证有效期结束时间'
                                                }
                                            </DatePicker>
                                        </Form.Item>
                                    }
                                </>
                            )
                        }}
                    </Form.Item> */}

                    <Form.Item
                        name='mobile'
                        label='手机号码'
                        rules={[
                            { required: true, message: '手机号码不能为空' },
                            {
                                pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
                                message: '请输入正确的手机号码',
                            },
                        ]}
                    >
                        <Input placeholder='请输入手机号码' maxLength={11} />
                    </Form.Item>

                    <Form.Item
                        name="code"
                        label='短信验证码'
                        rules={[{ required: true, message: '短信验证码不能为空' }]}
                        extra={
                            <Button
                                size='small'
                                color="primary"
                                fill="none"
                                onClick={send}
                                disabled={counter > 0}
                            >
                                {counter > 0 ? `${counter}s` : '发送验证码'}
                            </Button>
                        }
                    >
                        <Input placeholder='请输入' />
                    </Form.Item>

                    {/* <Form.Item
                        name='sex'
                        label='性别'
                    >
                        <Radio.Group>
                            <Space direction='vertical'>
                                {
                                    sex?.options.map(e => (
                                        <Radio key={e.value} value={e.value}>{e.name}</Radio>
                                    ))
                                }
                            </Space>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item
                        name='nation_id'
                        label='民族'
                        trigger="onConfirm"
                        onClick={(e, ref) => {
                            ref.current.open();
                        }}
                    >
                        <Picker
                            columns={[nation?.options.map(e => ({ label: e.name, value: e.value })) ?? []]}
                        >
                            {value =>
                                value && value[0] ? value[0].label : '请选择民族'
                            }
                        </Picker>
                    </Form.Item>

                    <Form.Item
                        name='birthday'
                        label='生日'
                        trigger='onConfirm'
                        onClick={(e, datePickerRef) => {
                            datePickerRef.current?.open()
                        }}
                    >
                        <DatePicker>
                            {value =>
                                value ? dayjs(value).format('YYYY-MM-DD') : '请选择日期'
                            }
                        </DatePicker>
                    </Form.Item>

                    <Form.Item
                        name='address'
                        label='地址'
                    >
                        <Input placeholder='请输入地址' />
                    </Form.Item> */}

                    <Form.Item
                        name='bank_no'
                        label='银行卡号'
                        rules={[
                            { required: true, message: '银行卡号不能为空' },
                            {
                                pattern: /^([1-9]{1})(\d{14}|\d{15}|\d{16}|\d{17}|\d{18})$/,
                                message: '请输入正确的银行卡号',
                            },
                        ]}
                    >
                        <Input placeholder='请输入银行卡号' />
                    </Form.Item>

                    <Form.Item
                        name='bank_name'
                        label='银行名称'
                        rules={[{ required: true, message: '银行名称不能为空' }]}
                    >
                        <Input placeholder='请输入银行名称' />
                    </Form.Item>

                    <Form.Item
                        name='bank_deposit'
                        label='开户支行'
                        rules={[{ required: true, message: '开户支行不能为空' }]}
                    >
                        <Input placeholder='请输入开户支行' />
                    </Form.Item>

                    <Form.Item
                        name='bank_place'
                        label='开户地'
                        rules={[{ required: true, message: '开户地不能为空' }]}
                    >
                        <Input placeholder='请输入开户地' />
                    </Form.Item>

                    <Form.Item
                        name='check'
                        valuePropName="checked"
                        rules={[
                            { required: true, message: '请勾选同意签约协议' },
                            {
                                pattern: /^true$/,
                                message: '请勾选同意签约协议',
                            },
                        ]}
                    >
                        <Checkbox>
                            同意<Button color="primary" fill="none" onClick={() => setVisible(true)}>《电签协议》</Button>
                        </Checkbox>
                    </Form.Item>
                </>
            }
        </Form>

        <Popup
            visible={visible}
            onMaskClick={() => {
                setVisible(false)
            }}
        >
            <div
                style={{ height: '60vh', overflowY: 'scroll', padding: '20px' }}
            >
                <Agreement />
            </div>
        </Popup>

        <SafeArea position='bottom' />
    </div>
}
